
class InfiniteScrollOptions{
    // rowMargin:number=0;
    debug:boolean=false;
    containerElement:HTMLDivElement;
    panelContainerElement:HTMLDivElement;
    enableLoadMore:boolean=false;
    footerElement:HTMLDivElement;
    panelCallbackObj:object;
    panelHeightCallback:string;
    panelRenderCallback:string;
    panelClass:string='';
    count:number=0;
    constructor(containerElement:HTMLDivElement, panelContainerElement:HTMLDivElement, footerElement:HTMLDivElement, panelCallbackObj:object, panelHeightCallback:string, panelRenderCallback:string, enableLoadMore:boolean) {
        this.containerElement=containerElement;
        this.panelContainerElement=panelContainerElement;
        this.panelCallbackObj=panelCallbackObj;
        this.panelHeightCallback=panelHeightCallback;
        this.footerElement=footerElement;
        this.panelRenderCallback=panelRenderCallback;
        this.enableLoadMore=enableLoadMore;
    }
}
class InfiniteScrollPanel{
    element:HTMLAnchorElement;
    offset:number=0; // position in the panel array
    x:number=0;
    y:number=0;
    rendered:boolean=false;
    visible:boolean=false;

    constructor(element:HTMLAnchorElement) {
        this.element=element;
    }
    destroy(){
        if(this.element && this.element.parentNode) {
            ZIDX.$(this.element).remove();
        }
        this.rendered=false;
    }
    render(html:string){
        ZIDX.$(this.element).html(html);
    }
}

class InfiniteScroll{
    options:InfiniteScrollOptions;
    panelDimensionElement:HTMLDivElement;
    panels:InfiniteScrollPanel[]=[];
    panelDimensions:DOMRect;
    startVisibleIndex:number=0;
    endVisibleIndex:number=0;
    columnCount:number=1;
    resultLimit:number=2000; // prevent more then 2000 because c++ app limits for performance
    rowHeight:number=0;
    containerPosition:DOMRect;
    footerPosition:DOMRect;
    panelContainerPosition:DOMRect;
    loadPerPage:number=9;
    loadedCount:number=this.loadPerPage;
    constructor(props:InfiniteScrollOptions) {
        this.options=props;
        this.containerPosition=this.options.containerElement.getBoundingClientRect();
        this.panelContainerPosition = this.options.panelContainerElement.getBoundingClientRect();
        let divTest=document.getElementById("zidxPanelDimensionTest");
        this.footerPosition=this.options.footerElement.getBoundingClientRect();
        ZIDX.$(".zidxLoadMoreButton").off().on("click", ()=> {
            console.log("load more clicked");
            this.loadedCount+=this.loadPerPage;
            this.update();
        });
        if(divTest==null) {
            this.panelDimensionElement = document.createElement("div");
            this.panelDimensionElement.id = "zidxPanelDimensionTest";
            this.panelDimensionElement.className = this.options.panelClass;
            this.panelDimensionElement.style.zIndex = "1";
            this.panelDimensionElement.style.position="absolute !important";
            this.panelDimensionElement.style.left = "-1000px !important";
            this.panelDimensionElement.style.top = "-1000px !important";
            this.options.panelContainerElement.appendChild(this.panelDimensionElement);
        }else{
            this.panelDimensionElement=<HTMLDivElement>divTest;
        }
        this.panelDimensions=this.panelDimensionElement.getBoundingClientRect();
        this.getPanelDimensions();
        this.areVisiblePanelsChanged();
        this.updatePanels();
        let self=this;
        ZIDX.$(window).on("clientresize resize", function(){
            let position=self.options.containerElement.getBoundingClientRect();
            if(position.width==0){
                return;
            }
            self.resize();
        });
        ZIDX.$(this.options.containerElement).on("mousedown touchstart", function() {
            if(typeof window.ZWIXAppScroll!="undefined") {
                window.Wix.scrollTo(0, window.ZWIXAppScroll.y-window.ZWIXBannerOffset);
            }
        });
        ZIDX.$(this.options.containerElement).on("scroll wheel", function(){
            let position=self.options.containerElement.getBoundingClientRect();
            if(position.width==0){
                return;
            }
            self.update();
        })
    }
    updatePanelsVisibility(){
        this.containerPosition=this.options.containerElement.getBoundingClientRect();
        for(let i in this.panels) {
            let panel = this.panels[i];
            this.updatePanelVisibility(panel);
        }
    }
    updatePanelVisibility(panel:InfiniteScrollPanel){
        if(!panel.element) return;
        let position = panel.element.getBoundingClientRect();
        if(this.options.enableLoadMore || position.top<=this.containerPosition.bottom && position.bottom+this.options.containerElement.scrollTop>=this.options.containerElement.scrollTop){
            let $panelElement=ZIDX.$(".zidxImageNotLoaded", panel.element);
            panel.visible=true;
            if($panelElement.length>0) {
                ZIDX.$($panelElement[0]).attr("style", ZIDX.getListingBackgroundImageCSS($panelElement.attr("data-image")!, true));
                $panelElement.removeClass("zidxImageNotLoaded");
            }
        }else{
            panel.visible=false;
        }
    }
    getPanelDimensions(){
        // console.log("this.options.containerElement:", this.options.containerElement);
        // console.log("this.options.panelContainerElement:", this.options.panelContainerElement);
        // console.log("this.panelDimensionElement:", this.panelDimensionElement);
        this.containerPosition=this.options.containerElement.getBoundingClientRect();
        if(this.options.debug) console.log("containerPosition:", this.containerPosition);
        // this.options.panelContainerElement.style.width=Math.floor(this.containerPosition.width)+"px";
        this.panelDimensions=this.panelDimensionElement.getBoundingClientRect();
        // @ts-ignore
        let panelHeight=this.options.panelCallbackObj[this.options.panelHeightCallback](this.panelDimensions);
        // console.log("panelHeight:", panelHeight);
        // can't do this on ie11 in strict mode
        // this.panelDimensions.height=panelHeight;
        if(this.options.debug) console.log("panelDimensions (it's ok that height is 0:", this.panelDimensions);
        this.panelContainerPosition=this.options.panelContainerElement.getBoundingClientRect();
        this.columnCount=Math.floor(this.panelContainerPosition.width/this.panelDimensions.width);
        if(this.options.debug) console.log("this.columnCount:",this.columnCount);
        let marginPixels=this.panelContainerPosition.width%this.panelDimensions.width;
        let rowMargin=0;
        if(this.columnCount>1){
            rowMargin=Math.round(marginPixels/(this.columnCount-1));
        }
        // rowMargin=0;
        this.rowHeight=panelHeight+rowMargin;
        let containerHeight=Math.round(Math.ceil(Math.min(this.resultLimit, this.options.count)/this.columnCount)*this.rowHeight);
        if(this.options.enableLoadMore) {
            containerHeight=this.rowHeight*(this.loadedCount/3);
            this.options.footerElement.style.display="none";
            ZIDX.$(".zidxFooterDisclaimerContainer").css("display", "block");
            ZIDX.$(".zidxLoadMoreContainer").css("display", "block");
        }else{
        }

        this.options.footerElement.style.top=containerHeight+"px";
        containerHeight+=this.footerPosition.height+rowMargin;
        this.options.footerElement.style.left="0px";

        if(this.options.debug) console.log("rowMargin:", rowMargin, "rowHeight:", this.rowHeight);
        // if(!ZIDX.options.wixApp) {
        if(this.options.enableLoadMore) {
            // TODO enableLoadMore
            // this.panelDimensionElement.style.display="block !important";
            // this.panelDimensionElement.style.left = "100px !important";
            // this.panelDimensionElement.style.top = "100px !important";
            this.panelDimensions=this.panelDimensionElement.getBoundingClientRect();
            // console.log("enableLoadMore this.panelDimensions", this.panelDimensionElement, this.panelDimensions.height);
        }else{
        }
        if(!ZIDX.options.wixApp) {
            this.options.containerElement.style.height = (ZIDX.windowSize.height - this.containerPosition.top) + "px";
        }
        this.options.panelContainerElement.style.height = containerHeight + "px";
        // }
        this.panelContainerPosition=this.options.panelContainerElement.getBoundingClientRect();
        if(this.options.debug) console.log("panelContainerPosition:", this.panelContainerPosition);
    }
    areVisiblePanelsChanged():boolean{
        this.getPanelDimensions();
        let scrollTop=this.options.containerElement.scrollTop;
        if(this.options.debug) console.log("scrollTop:", scrollTop, " this.columnCount:", this.columnCount, " rowHeight:", this.rowHeight);
        let minRow=Math.max(0, Math.floor(scrollTop/this.rowHeight));
        let maxRow=minRow+Math.ceil(this.containerPosition.height/this.rowHeight)+1;
        if(this.options.debug) console.log("ZIDX.windowSize.height", ZIDX.windowSize.height, " containerPosition.top:", this.containerPosition.top, " containerPosition.height:", this.containerPosition.height, " minRow:", minRow, " maxRow:", maxRow);

        let newStart=minRow*this.columnCount;
        let newEnd=maxRow*this.columnCount;
        newEnd=Math.max(newEnd-1, 0);
        let changed=false;
        if(newStart!=this.startVisibleIndex || newEnd!=this.endVisibleIndex){
            changed=true;
            this.startVisibleIndex=newStart;
            this.endVisibleIndex=newEnd;
        }
        if(this.options.enableLoadMore) {
            this.endVisibleIndex = this.loadedCount - 1;
        }
        // if(this.options.enableLoadMore){
        //     this.startVisibleIndex=0;
        //     console.log("loadedCount", this.loadedCount);
        //     this.endVisibleIndex=this.loadedCount;
        //     return changed;
        // }
        if(this.options.debug) console.log("startVisibleIndex:", this.startVisibleIndex, " endVisibleIndex:", this.endVisibleIndex);
        return changed;
    }
    firstLoadSetup=true;
    marginPixels:number=0;
    updatePanelPosition(panel:InfiniteScrollPanel){
        // return;
        let row=Math.floor(panel.offset/this.columnCount);
        let column=Math.floor(panel.offset%this.columnCount);
        let columnGapPixels=0;
        // if(this.columnCount>1) {
        //     columnGapPixels = Math.round(this.marginPixels / (this.columnCount - 1));
        // }
        let newX=Math.floor((column*this.panelDimensions.width)+(column*columnGapPixels));
        let newY=Math.floor(row*this.rowHeight);
        // console.log("updatePanelPosition", newX, newY);
        if(newX!=panel.x || newY!=panel.y) {
            panel.x = newX;
            panel.y = newY;
            panel.element.style.cssFloat="none";
            panel.element.style.position="absolute";
            panel.element.style.left = Math.round(panel.x) + "px";
            panel.element.style.top = Math.round(panel.y) + "px";
            if(this.options.debug) console.log("move panel:"+panel.offset+" to left:"+panel.x+" top:"+panel.y);
        }
    }
    destroyPanels(){
        for(let i in this.panels){
            this.panels[i].destroy();
        }
        this.panels=[];
        this.startVisibleIndex=0;
        this.endVisibleIndex=0;
    }
    updatePanels(){
        let newPanels:InfiniteScrollPanel[]=[];
        for(let i in this.panels){
            let panel=this.panels[i];
            if(typeof panel=="undefined"){
                continue;
            }
            panel.element.getBoundingClientRect();
            if(this.startVisibleIndex>panel.offset || this.endVisibleIndex<panel.offset) {
                if(this.options.debug) console.log("destroy panel:", i);
                panel.destroy();
            }else{
                newPanels[panel.offset]=panel;
            }
        }
        // console.log("display panels", "startVisibleIndex", this.startVisibleIndex, "endVisibleIndex", this.endVisibleIndex);
        // return;
        this.panels=newPanels;
        this.marginPixels=this.panelContainerPosition.width%this.panelDimensions.width;
        if(this.options.debug) console.log("marginPixels:", this.marginPixels);
        if(this.firstLoadSetup){
            this.firstLoadSetup=false;
            for(let i=0;i<30;i++){
                let div=document.getElementById(this.options.panelClass+i);
                if(div==null) {
                    break;
                }
                let panel = new InfiniteScrollPanel(<HTMLAnchorElement>div);
                panel.offset=i;
                this.updatePanelPosition(panel);
                panel.rendered=true;
                this.panels[i]=panel;
            }
        }
        // console.log("updating panels:", Math.min(Math.min(this.resultLimit, this.options.count)-1, this.endVisibleIndex), this.startVisibleIndex, this.endVisibleIndex);
        for(let i=this.startVisibleIndex;i<=Math.min(Math.min(this.resultLimit, this.options.count)-1, this.endVisibleIndex);i++){
            if(typeof this.panels[i]=="undefined") {
                let div=document.getElementById(this.options.panelClass+i);
                if(div==null) {
                    let newDiv = document.createElement("a");
                    newDiv.className = this.options.panelClass;
                    newDiv.id = this.options.panelClass + i;
                    this.panels[i] = new InfiniteScrollPanel(<HTMLAnchorElement>newDiv);

                    // console.log("infinite panel1", this.panels[i]);
                    this.options.panelContainerElement.appendChild(this.panels[i].element);
                }else{
                    this.panels[i] = new InfiniteScrollPanel(<HTMLAnchorElement>div);
                    // console.log("infinite panel2", this.panels[i]);
                    this.panels[i].rendered=true;
                }
            }
            let panel=this.panels[i];
            panel.offset=i;
            this.updatePanelPosition(panel);
            this.updatePanelVisibility(panel);
            if(!panel.rendered) {
                // console.log("called panel render callback for panel offset", panel.offset);
                // @ts-ignore
                this.options.panelCallbackObj[this.options.panelRenderCallback](panel);
            }else{
                // console.log("already rendered panel:"+i);
            }
        }
    }
    resize(){
        this.getPanelDimensions();
        this.areVisiblePanelsChanged();
        this.updatePanels();
    }
    update(){
        // check size of panel
        if(this.areVisiblePanelsChanged()) {
            this.updatePanels();
        }
    }
}